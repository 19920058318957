h2,
h3,
h4,
p,
ul,
li {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
}
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
  /*overflow-x: hidden;*/
  /*overflow-y: auto;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  cursor: pointer;
  border: none;
  font-family: 'Roboto', sans-serif;
}

.span_colorize_43 {
  color: hsl(39, 100%, 55%);
  font-weight: 600;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(250, 250, 250, 0.7);
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(250, 250, 250, 0.7);
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(250, 250, 250, 0.7);
}
:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(250, 250, 250, 0.7);
}
